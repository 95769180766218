import { Input, InputVariants } from '@/components/atoms/Input/Input';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import {
  CALC_LEASE_LOAN_TERM_OPTIONS,
  CALC_LEASE_MILAGE_OPTIONS,
  INCENTIVES_DISCLAIMER,
} from '@/lib/constants';
import { CalculatorInstanceProps } from '@/types';
import Accordion from '@/components/molecules/Accordion/Accordion';
import FormSelect from '@/components/molecules/FormSelect';
import { useCalculator } from '@/hooks/paymentCalculator/useFinanceCalculator';
import {
  BreakdownItem,
  BreakdownSectionDivider,
  CalculatorWrapper,
  Total,
} from './Wrappers';

export const LeaseCalculator = ({
  type,
  mode = 'Lease',
  setMode,
  expanded = true,
  setExpanded,
  handleOfferButton,
  salePrice,
  setSalePrice,
  incentivesTotal,
  fuelSavings,
  onChangeMonthlyEstimatedPayment,
  onChangeTerm,
}: CalculatorInstanceProps) => {
  const {
    downPaymentType,
    downPayment,
    downPaymentPercent,
    downPaymentValid,
    residualValueType,
    residualPercent,
    residualValue,
    loanOrLeaseTerm: leaseTerm,
    aprOrInterestRate: interestRate,
    monthlyEstimatedPayment,
    totalAfterDeductions,
    milageFactorIndex,
    handleChangeMileageFactor,
    handleChangeMonthlyEstimatedPayment,
    handleChangeSalePrice,
    handleChangeAprOrInterestRate: handleChangeInterestRate,
    handleChangeDownPaymentType,
    handleChangeDownPayment,
    handleChangeTerm: handleChangeLeaseTerm,
    handleChangeResidualValue,
    handleChangeResidualValueType,
    handleChangeMode,
  } = useCalculator({
    type,
    mode: 'Lease',
    salePrice,
    setSalePrice,
    incentivesTotal,
    fuelSavings,
    setMode,
    onChangeMonthlyEstimatedPayment,
    onChangeTerm,
  });

  const inputs = (
    <div className="flex flex-grow flex-col gap-s">
      {setSalePrice ? (
        <Input
          type={InputVariants.Number}
          aria-label="Listed price"
          value={salePrice.toString()}
          onChange={(val) => handleChangeSalePrice(val)}
          label="Listed price"
          prepend="$"
          placeholder="0"
          step={1000}
        />
      ) : (
        <Input
          id="salePrice"
          label="Listed price"
          aria-label="Listed price"
          type={InputVariants.Filled}
          value={`$${salePrice.toString()}`}
        />
      )}
      <Input
        aria-label="Est. Interest rate"
        type={InputVariants.Number}
        value={interestRate.toString()}
        onChange={(val) => handleChangeInterestRate(val)}
        label="Est. Interest rate %"
        append="%"
        placeholder="8.50"
        step={0.01}
      />
      <Input
        aria-label="Down Payment"
        type={InputVariants.Number}
        value={
          downPaymentType === '$'
            ? downPayment.toString()
            : downPaymentPercent.toString()
        }
        onChange={(val) => handleChangeDownPayment(val)}
        invalid={
          (downPaymentType === '$' && downPayment >= salePrice) ||
          (downPaymentType === '%' &&
            (downPaymentPercent >= 100 || downPaymentPercent < 0))
        }
        label="Down Payment"
        float={0}
        placeholder="0"
        suffixOption={{
          value: downPaymentType,
          options: [
            { label: '$', value: '$' },
            { label: '%', value: '%' },
          ],
          setSuffixOption: (val) =>
            handleChangeDownPaymentType(val as '$' | '%'),
        }}
      />
      {downPaymentValid && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}

      <FormSelect
        value={[CALC_LEASE_MILAGE_OPTIONS[milageFactorIndex].value]}
        label="Annual mileage"
        options={CALC_LEASE_MILAGE_OPTIONS}
        onChange={(val) => {
          if (val.length === 0) return;
          handleChangeMileageFactor(
            CALC_LEASE_MILAGE_OPTIONS.findIndex((opt) => opt.value === val[1])
          );
        }}
      />

      <div>
        <div className="label mb-xs block text-body2Medium text-neutral-800">
          Lease Term in months
        </div>
        <Switcher
          aria-label="Lease Term in months"
          options={CALC_LEASE_LOAN_TERM_OPTIONS.map((option) => ({
            text: option,
            value: option,
          }))}
          value={leaseTerm}
          onChange={(e) => handleChangeLeaseTerm(e)}
        />
      </div>

      <Input
        aria-label="Residual value of the vehicle"
        type={InputVariants.Number}
        value={
          residualValueType === '$'
            ? residualValue.toString()
            : residualPercent.toString()
        }
        onChange={(val) => handleChangeResidualValue(val)}
        invalid={downPaymentValid}
        label="Residual value"
        float={0}
        suffixOption={{
          value: residualValueType,
          options: [
            { label: '$', value: '$' },
            { label: '%', value: '%' },
          ],
          setSuffixOption: (val) =>
            handleChangeResidualValueType(val as '$' | '%'),
        }}
      />
      {downPaymentValid && (
        <div className="text-microMedium text-red-medium">
          Must be less than sale price
        </div>
      )}
    </div>
  );

  const inputsResponsiveWrapper = (
    <>
      <div className="flex m:hidden">
        <Accordion
          title={'Customize Payments'}
          buttonClassName="text-neutral-900"
          className="flex-grow"
          icon={{ id: 'chevron' }}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          {inputs}
        </Accordion>
      </div>
      <div className="hidden m:flex">{inputs}</div>
    </>
  );

  const breakdown = (
    <div className="flex flex-col gap-s">
      <BreakdownItem
        label="Listed price"
        val={`$${salePrice.toLocaleString()}`}
      />

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Est. Interest rate"
        val={`${interestRate.toFixed(2).toLocaleString()}%`}
      />
      <BreakdownItem
        label="Down Payment"
        val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
      />

      {incentivesTotal || fuelSavings ? <BreakdownSectionDivider /> : null}

      {incentivesTotal ? (
        <BreakdownItem
          label="Incentives and Rebates"
          val={`${incentivesTotal > 0 ? '-' : ''}$${
            incentivesTotal > 0 ? incentivesTotal.toLocaleString() : 0
          }`}
          tooltip={INCENTIVES_DISCLAIMER}
        />
      ) : null}
      {fuelSavings ? (
        <BreakdownItem
          label={`Fuel Savings over ${Number(leaseTerm) / 12} years`}
          val={`-$${(
            (Number(fuelSavings.fuelSavingsEstimate) *
              (Number(leaseTerm) / 12)) /
            Number(fuelSavings.timePeriod)
          ).toLocaleString()}`}
          tooltip={`Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for ${Intl.NumberFormat('en-US').format(Number(fuelSavings.annualMiles))} annual miles. Costs are based on national averages: $${Number(fuelSavings.gasPerGallon).toFixed(2)} per gallon of gasoline and $${Number(fuelSavings.electricityPerKwh).toFixed(2)} per kWh for electricity. Actual savings may vary due to market changes and driving conditions. Consumption ratings are provided for both electric vehicles (in kWh/100mi) and gasoline vehicles (in MPG).`}
        />
      ) : null}

      <BreakdownSectionDivider strong />

      <BreakdownItem
        label="Total lease amount"
        val={`$${totalAfterDeductions.toLocaleString()}`}
      />

      <Total label="Total" monthlyTotal={monthlyEstimatedPayment} />

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Residual value of the vehicle"
        val={`$${residualValue.toLocaleString()}`}
      />
    </div>
  );

  return (
    <CalculatorWrapper
      type={type}
      mode={mode}
      inputs={inputsResponsiveWrapper}
      breakdown={breakdown}
      monthlyEstimatedPayment={monthlyEstimatedPayment}
      handleChangeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
      handleChangeMode={handleChangeMode}
      handleOfferButton={handleOfferButton}
    />
  );
};
