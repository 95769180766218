import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Link from '@/components/atoms/legacy/Link';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { InfoDisplay } from '@/components/molecules/InfoDisplay';
import { ProductListingLinkFooter } from '@/components/molecules/ProductListingLinkFooter/ProductListingLinkFooter';
import { ProductListingLinkPrice } from '@/components/molecules/ProductListingLinkPrice/ProductListingLinkPrice';
import { CallModal } from '@/components/organisms/CallModal';
import { useSavedVehicles } from '@/context/SavedVehicles';

import { CheckAvailabilityButton } from '@/components/molecules/CheckAvailabilityButton';
import { useDealer } from '@/context/DealerContact';
import {
  GOOGLE_TRACK_INFO,
  PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION,
} from '@/lib/constants';
import {
  FilterState,
  IndividualSavedVehicleProps,
  JDPowerIncentive,
  VehicleBadgeIds,
  VehicleListingType,
} from '@/types';
import { FuelTypes } from '@/types/enums';
import { useRouter } from 'next/router';
import React, { ForwardedRef, useCallback, useMemo, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import {
  DealerMessageBodyType,
  createDealerMessageBody,
} from '@/lib/createDealerMessageBody';
import {
  findPurchaseFederalIncentive,
  getTotalIncentiveAmount,
  getTotalRebateAmount,
  returnPurchaseFederalIncentive,
} from '@/lib/incentiveUtils';
import { VLPCardImageContainer } from '@/components/molecules/VLPCardImageContainer/VLPCardImageContainer';
import { BaseProductListingLinkProps } from '../LoadingCards/LoadingCards';

interface ProductListingLinkProps
  extends BaseProductListingLinkProps,
    IndividualSavedVehicleProps {
  product: VehicleListingType;
  isListedPrice: boolean;
  isMobile: boolean;
  filters?: FilterState;
  showNewCheckAvailabilityButton: boolean;
  contactedDealer: boolean;
  estFuelSavingsByType?: Record<string, { savings: number }> | null;
  displayIncentivesAmount?: boolean;
  displayRebatesAmount?: boolean;
  displayFuelSavingsAmount?: boolean;
}

function ListingLink(
  {
    product,
    isColumnLayout = false,
    isSaved: initiallySaved,
    isListedPrice,
    isMobile,
    filters,
    showNewCheckAvailabilityButton,
    contactedDealer,
    estFuelSavingsByType,
    displayIncentivesAmount = false,
    displayRebatesAmount = false,
    displayFuelSavingsAmount = false,
  }: ProductListingLinkProps,
  ref: ForwardedRef<HTMLDivElement> | null
) {
  const [isCheckAvailabilityDialogOpen, setIsCheckAvailabilityDialogOpen] =
    useState(false);
  const router = useRouter();
  const [showCallModal, setShowCallModal] = useState(false);
  const { images, price, make, year, model, trim, dealer } = product;
  const dealerInfo = useMemo(
    () => ({
      City: dealer?.city || '',
      State: dealer?.province || '',
      Dealership: dealer?.name || '',
      Phone: dealer?.phone || '',
      DealerID: dealer?.dealerID || '',
      PostalCode: dealer?.postalCode || '',
    }),
    [dealer]
  );

  const { stateIncentives, localIncentives, federalIncentives } = useMemo(
    () => ({
      stateIncentives: product.incentives?.State || [],
      localIncentives: product.incentives?.local || [],
      federalIncentives: returnPurchaseFederalIncentive(
        product.incentives?.Federal || []
      ),
    }),
    [product.incentives]
  );

  let totalEstFuelSavings = 0;
  if (estFuelSavingsByType) {
    const bodyTypeData = estFuelSavingsByType[product.body];
    if (bodyTypeData) {
      totalEstFuelSavings = Math.round(bodyTypeData.savings);
    }
  }

  const vehicleId = product.listingId;
  const badges = product?.badges;

  const hasIncentives =
    stateIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    localIncentives.some(
      (incentive: JDPowerIncentive) => !!incentive.max_amount
    ) ||
    federalIncentives.some((incentive: JDPowerIncentive) =>
      findPurchaseFederalIncentive(incentive)
    );

  const monetaryIncentiveAmount = getTotalIncentiveAmount([
    ...stateIncentives,
    ...localIncentives,
    ...federalIncentives,
  ]);

  const rebateAmount = getTotalRebateAmount([
    ...stateIncentives,
    ...localIncentives,
    ...federalIncentives,
  ]);

  const hasStateLocalButNoFederalBadge =
    hasIncentives &&
    !badges.includes('ELIGIBLE FOR FEDERAL INCENTIVES') &&
    process.env.NEXT_PUBLIC_ENABLE_INCENTIVES === 'true' &&
    filters?.fields.has_ev_incentives === true;

  const {
    saved,
    saveVehicle,
    loading: savingVehicle,
  } = useSavedVehicles({
    storeField: 'listing',
    initiallySaved,
    infoToSave: {
      id: vehicleId,
      price: price.displayValue,
      year,
      make,
      model,
      trim,
      image: images[0],
    },
  });

  const { contactDealer } = useDealer();
  const { user } = useAuth();

  const infoBlocks: { text: string; postFix: string }[] = useMemo(() => {
    const result: { text: string; postFix: string }[] = [];

    if (product.mileage) {
      result.push({
        text: `${product.mileage.value}`,
        postFix: 'Miles',
      });
    }

    if (product.batteryInfo?.batterySize) {
      result.push({
        text: product.batteryInfo.batterySize,
        postFix: 'Battery size',
      });
    }

    if (product.rangeInfo) {
      result.push({
        text: `${product.rangeInfo.value} ${product.rangeInfo.unit}`,
        postFix: 'Est. Range',
      });
    }

    return result;
  }, [product]);

  const handleClickHeartButton = useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e) {
        e.stopPropagation();
      }
      await saveVehicle(vehicleId);
    },
    [saveVehicle, vehicleId]
  );

  const handleMessageDealer = useCallback(async () => {
    const baseMessageBody: DealerMessageBodyType = {
      product,
      dealer,
      user,
      estimatedFuelSavingsPerYear: totalEstFuelSavings,
      localRebates: localIncentives,
      stateRebates: stateIncentives,
    };

    const body = await createDealerMessageBody(baseMessageBody);

    await contactDealer({
      body,
      onSuccess: async () => {
        if (!saved) {
          await saveVehicle(vehicleId, { hideToast: true });
        }
      },
    });
  }, [
    contactDealer,
    saveVehicle,
    saved,
    vehicleId,
    product,
    dealer,
    user,
    localIncentives,
    stateIncentives,
    totalEstFuelSavings,
  ]);

  const cta = (
    <section className="flex w-full justify-center gap-l">
      <div className="flex w-full shrink-2">
        <Button
          aria-label="Call dealer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isMobile) {
              window.open(`tel: ${dealer?.phone}`, '_self');
            } else {
              setShowCallModal(!showCallModal);
            }
          }}
          analyticsEvent={{
            ...GOOGLE_TRACK_INFO.callDealerButton,
            pageTarget: dealer?.phone
              ? `/vehicle/${product.listingId}?dealerPhone=${dealer.phone}`
              : `/vehicle/${product.listingId}`,
          }}
          variant={ButtonVariants.Secondary}
          size="small"
        >
          Call
        </Button>
      </div>
      <div className="w-full">
        {showNewCheckAvailabilityButton ? (
          <CheckAvailabilityButton
            backGroundImage={product.images[0]}
            onClick={handleMessageDealer}
            contactedDealer={contactedDealer}
            isAuthDialogOpen={isCheckAvailabilityDialogOpen}
            setIsAuthDialogOpen={setIsCheckAvailabilityDialogOpen}
          />
        ) : (
          <Button
            aria-label="Check availability"
            onClick={async (e) => {
              e.preventDefault();
              await router.push({
                pathname: `/vehicle/[listingId]`,
                query: {
                  listingId: product.listingId,
                  scrollTo: 'form',
                },
              });
            }}
            variant={ButtonVariants.Tertiary}
          >
            Check availability
          </Button>
        )}
      </div>
    </section>
  );

  const analyticsEvent = {
    ...GOOGLE_TRACK_INFO.vehicleDetailPageButton,
    pageTarget: `/listingId=${product.listingId}`,
  };
  const href = {
    pathname:
      product.listingId || product.shortListingId
        ? '/vehicle/[listingId]'
        : '/vehicle/by-vin/[vin]',
    query: {
      ...(product.shortListingId || product.listingId
        ? { listingId: product.shortListingId || product.listingId }
        : { vin: product.vin }),
    },
  };

  return (
    <div className="flex h-full w-full" ref={ref}>
      <div className="border-1 flex w-full flex-col rounded-lg border-[1px] border-neutral-200 shadow-md transition-colors duration-500 hover:border-brandElectric-hover hover:shadow-lg">
        <div
          className={`flex flex-1 flex-col rounded-lg bg-background-white ${
            isColumnLayout ? '' : 'm:flex-row'
          }`}
        >
          <div className={`flex ${isColumnLayout ? '' : 'lg:basis-2/5'}`}>
            <Link
              aria-label={`link to vehicle ${product.year} ${product.make} ${product.model}`}
              analyticsEvent={analyticsEvent}
              href={href}
            >
              <VLPCardImageContainer
                image={product.images[0]}
                make={product.make}
                model={product.model}
                year={String(product.year)}
                isMobile={isMobile}
                isGridView={!isColumnLayout}
                handleSaveVehicle={handleClickHeartButton}
                saved={saved}
                savingVehicle={savingVehicle}
              />
            </Link>
          </div>
          <section className="flex flex-1 flex-col">
            <Link
              aria-label={`link to vehicle ${product.year} ${product.make} ${product.model}`}
              analyticsEvent={analyticsEvent}
              href={href}
            >
              <div
                className={`flex h-full w-full flex-col justify-between ${
                  isColumnLayout ? '' : 'md:flex-1 md:basis-3/5'
                } gap-l px-xl pb-xl pt-l`}
              >
                <section className="flex flex-col">
                  <section className="mb-s flex justify-between">
                    <div className="flex flex-wrap gap-s">
                      {filters?.fields.fuelType === FuelTypes.Hybrid && (
                        <Badge
                          className="!text-blue-dark"
                          label={PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION}
                          variant={BadgeVariants.Blue}
                        />
                      )}
                      {filters?.fields.condition === 'New' && (
                        <Badge
                          className="bg-neutral-200 text-neutral-800"
                          label={filters?.fields.condition}
                        />
                      )}
                      {badges?.map((badge, i) => (
                        <Badge
                          key={`badge-${i}`}
                          variant={BadgeVariants.Blue}
                          label={VehicleBadgeIds[badge]}
                        />
                      ))}
                      {hasStateLocalButNoFederalBadge && (
                        <Badge
                          variant={BadgeVariants.Blue}
                          label={
                            VehicleBadgeIds['ELIGIBLE FOR FEDERAL INCENTIVES']
                          }
                        />
                      )}
                    </div>
                  </section>
                  <section className="flex flex-row items-center justify-between">
                    <div
                      className={`flex w-full gap-xs ${
                        isColumnLayout ? 'flex-col' : 'flex-row'
                      }`}
                    >
                      <div className="flex gap-xs whitespace-nowrap">
                        <div className="text-body1Regular text-neutral-900">
                          {product.year} {product.make} {product.model}
                        </div>
                        <div className="overflow-hidden text-ellipsis text-body1Light text-neutral-800">
                          {product.trim}
                        </div>
                      </div>
                      <div
                        className={`text-body1Light text-neutral-600 ${isColumnLayout ? '' : 'hidden'}`}
                      >
                        {product.mileage?.value} {product.mileage?.unit}
                      </div>
                    </div>
                  </section>
                  <section
                    className={`flex flex-col ${
                      isColumnLayout ? 'hidden' : 'mt-s'
                    }`}
                  >
                    <div className="flex h-full flex-row">
                      {infoBlocks.map(({ text, postFix }, i) => (
                        <InfoDisplay
                          key={i}
                          className={`px-4 ${
                            i !== infoBlocks.length - 1
                              ? 'border-r border-[#E5E7EB]'
                              : ''
                          } ${i === 0 && !isColumnLayout ? 'md:px-0 md:pr-4' : ''}`}
                          textContainerClassName="flex-col items-start justify-center"
                          main={{
                            text,
                            postFix,
                            className: 'text-[16px] font-normal',
                            postFixClassName: 'text-micro1 font-normal !pl-0',
                          }}
                        />
                      ))}
                    </div>
                  </section>
                </section>
                <section
                  className={`flex flex-col justify-between gap-l ${
                    isColumnLayout ? '' : 'l:flex-row'
                  }`}
                >
                  <ProductListingLinkPrice
                    isListedPrice={isListedPrice}
                    price={price.value}
                    monetaryIncentiveAmount={monetaryIncentiveAmount}
                    rebateAmount={rebateAmount}
                    totalEstFuelSavings={totalEstFuelSavings}
                    displayIncentivesAmount={displayIncentivesAmount}
                    displayRebatesAmount={displayRebatesAmount}
                    displayFuelSavingsAmount={displayFuelSavingsAmount}
                  />
                  <div className="flex items-end bg-transparent">{cta}</div>
                </section>
              </div>
            </Link>
            <div
              className={`flex flex-row items-center justify-evenly rounded-lg bg-background-light ${
                isColumnLayout ? 'mt-auto' : ''
              }`}
            >
              <ProductListingLinkFooter
                aria-label={`link to dealer ${dealerInfo}`}
                dealerInfo={dealerInfo}
                isColumnLayout={isColumnLayout}
                filters={filters}
              />
            </div>
          </section>
        </div>
        {dealer && (
          <CallModal
            open={showCallModal}
            onClose={() => {
              setShowCallModal(false);
              if (!user) {
                setTimeout(() => {
                  setIsCheckAvailabilityDialogOpen(true);
                }, 2000);
              }
            }}
            productId={product.listingId}
            dealer={dealer}
          />
        )}
      </div>
    </div>
  );
}

export const ProductListingLink = React.forwardRef(ListingLink);
