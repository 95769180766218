import { CalculatorFuelSavingsProps } from '@/types';

export const calculatedMonthlyPaymentFinance = (
  apr: number,
  totalAfterDeductions: number,
  loanTerm: number,
  fuelSavings?: CalculatorFuelSavingsProps
) => {
  const rate = apr / 100;

  let monthlyPayment: number;
  if (apr > 0) {
    monthlyPayment = Math.max(
      0,
      (totalAfterDeductions * (rate / 12)) / (1 - (1 + rate / 12) ** -loanTerm)
    );
  } else {
    monthlyPayment = Math.max(0, totalAfterDeductions / Number(loanTerm));
  }

  if (fuelSavings) {
    monthlyPayment -=
      Number(fuelSavings.fuelSavingsEstimate) /
      (Number(fuelSavings.timePeriod) * 12);
  }

  return Math.max(monthlyPayment, 0);
};

export const calculateMSRPFinance = (
  monthlyEstimatedPayment: number,
  apr: number,
  loanTerm: number,
  downPayment: number,
  tradeInValue: number,
  incentivesTotal?: number,
  fuelSavings?: CalculatorFuelSavingsProps
) => {
  const rate = apr / 100;
  let totalAfterDeductions = 0;
  let monthlyPayment = monthlyEstimatedPayment;

  if (fuelSavings) {
    monthlyPayment +=
      Number(fuelSavings.fuelSavingsEstimate) /
      (Number(fuelSavings.timePeriod) * 12);
  }

  if (apr > 0) {
    totalAfterDeductions = Math.floor(
      Math.max(
        0,
        (monthlyPayment * (1 - (1 + rate / 12) ** -loanTerm)) / (rate / 12)
      )
    );
  } else {
    totalAfterDeductions = Math.max(0, monthlyPayment * loanTerm);
  }

  const newSalePrice =
    totalAfterDeductions + downPayment + tradeInValue + (incentivesTotal || 0);

  return newSalePrice;
};

const getMoneyFactor = (interestRate: number) => {
  return interestRate / 2400;
};

export const calculatedMonthlyPaymentLease = (
  apr: number,
  totalAfterDeductions: number,
  leaseTerm: number,
  residualValue: number,
  fuelSavings?: CalculatorFuelSavingsProps
) => {
  const monthlyDepreciation =
    (totalAfterDeductions - residualValue) / leaseTerm;
  const monthlyFinanceCharge =
    (totalAfterDeductions + residualValue) * getMoneyFactor(apr);
  let monthlyPayment = monthlyDepreciation + monthlyFinanceCharge;

  if (fuelSavings) {
    monthlyPayment -=
      Number(fuelSavings.fuelSavingsEstimate) /
      (Number(fuelSavings.timePeriod) * 12);
  }

  return Math.max(monthlyPayment, 0);
};

export const calculateMSRPLease = (
  monthlyEstimatedPayment: number,
  interest: number,
  leaseTerm: number,
  residualValue: number,
  downPayment: number,
  incentivesTotal?: number,
  fuelSavings?: CalculatorFuelSavingsProps
) => {
  let monthlyPayment = monthlyEstimatedPayment;
  if (fuelSavings) {
    monthlyPayment +=
      Number(fuelSavings.fuelSavingsEstimate) /
      (Number(fuelSavings.timePeriod) * 12);
  }

  const moneyFactor = getMoneyFactor(interest);

  const totalAfterDeductions =
    (monthlyPayment * leaseTerm + residualValue) /
    (1 + moneyFactor * leaseTerm);

  const salePrice = totalAfterDeductions + downPayment + (incentivesTotal || 0);

  return salePrice;
};
