import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CALC_FINANCE_INITIAL_APR,
  CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_LEASE_MILAGE_OPTIONS,
  CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT,
  CALC_LEASE_LOAN_TERM_OPTIONS,
  CALC_LEASE_INITIAL_INTEREST_RATE,
  CALC_INITIAL_FINANCE_TERM_INDEX,
  CALC_INITIAL_LEASE_TERM_INDEX,
} from '@/lib/constants';
import {
  calculatedMonthlyPaymentFinance,
  calculateMSRPFinance,
  calculatedMonthlyPaymentLease,
  calculateMSRPLease,
} from '@/lib/calculatedMonthlyPayment';
import { CalculatorFuelSavingsProps } from '@/types';

export const useCalculator = ({
  type,
  mode,
  salePrice,
  setSalePrice,
  incentivesTotal,
  fuelSavings,
  setMode,
  onChangeMonthlyEstimatedPayment,
  onChangeTerm,
}: {
  type: 'vlp' | 'vdp' | 'homepage';
  mode: 'Finance' | 'Lease';
  salePrice: number;
  setSalePrice?: (value: number) => void;
  incentivesTotal?: number;
  fuelSavings?: CalculatorFuelSavingsProps;
  setMode?: (val: 'Finance' | 'Lease') => void;
  onChangeMonthlyEstimatedPayment?: (value: number) => void;
  onChangeTerm?: (value: string) => void;
}) => {
  const isFinance = mode === 'Finance';

  const initialDownPaymentPercent = isFinance
    ? CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT
    : CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT;

  const initialLoanTerm = isFinance
    ? CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
    : CALC_LEASE_LOAN_TERM_OPTIONS[CALC_INITIAL_LEASE_TERM_INDEX];

  const initialAprOrInterestRate = isFinance
    ? CALC_FINANCE_INITIAL_APR
    : CALC_LEASE_INITIAL_INTEREST_RATE;

  const mileageOptions = CALC_LEASE_MILAGE_OPTIONS;

  const [milageFactorIndex, setMilageFactorIndex] = useState<number>(1);

  const [downPaymentType, setDownPaymentType] = useState<'%' | '$'>('%');
  const [downPayment, setDownPayment] = useState<number>(
    type === 'vlp'
      ? 0
      : Math.floor(initialDownPaymentPercent * (salePrice || 0))
  );
  const [downPaymentPercent, setDownPaymentPercent] = useState<number>(
    type === 'vlp' ? 0 : initialDownPaymentPercent * 100
  );

  const [loanOrLeaseTerm, setLoanOrLeaseTerm] =
    useState<string>(initialLoanTerm);
  const [aprOrInterestRate, setAprOrInterestRate] = useState<number>(
    type === 'vlp' ? 0 : initialAprOrInterestRate
  );
  const [monthlyBudget, setMonthlyBudget] = useState<number>(
    salePrice / Number(loanOrLeaseTerm)
  );

  const [tradeInValue, setTradeInValue] = useState<number>(0);

  const [residualValueType, setResidualValueType] = useState<'%' | '$'>('%');
  const [residualPercent, setResidualPercent] = useState<number>(
    CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT * 100
  );
  const [residualValue, setResidualValue] = useState<number>(
    Math.floor(CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT * (salePrice || 0))
  );

  const calculateTotalAfterDeductions = useCallback(() => {
    let currentTotal = salePrice - downPayment - tradeInValue;
    if (incentivesTotal && incentivesTotal > 0) {
      currentTotal -= incentivesTotal;
    }
    return currentTotal;
  }, [salePrice, downPayment, tradeInValue, incentivesTotal]);

  const [totalAfterDeductions, setTotalAfterDeductions] = useState<number>(
    calculateTotalAfterDeductions()
  );

  const calculateMonthlyPayment = useCallback(() => {
    if (isFinance) {
      return calculatedMonthlyPaymentFinance(
        aprOrInterestRate,
        totalAfterDeductions,
        Number(loanOrLeaseTerm),
        fuelSavings
      );
    }
    return calculatedMonthlyPaymentLease(
      aprOrInterestRate,
      totalAfterDeductions,
      Number(loanOrLeaseTerm),
      residualValue,
      fuelSavings
    );
  }, [
    isFinance,
    aprOrInterestRate,
    totalAfterDeductions,
    loanOrLeaseTerm,
    residualValue,
    fuelSavings,
  ]);

  const [monthlyEstimatedPayment, setMonthlyEstimatedPayment] =
    useState<number>(calculateMonthlyPayment());

  useEffect(() => {
    const newTotalAfterDeductions = calculateTotalAfterDeductions();
    setTotalAfterDeductions(newTotalAfterDeductions);

    const newMonthlyEstimate = calculateMonthlyPayment();
    setMonthlyEstimatedPayment(newMonthlyEstimate);

    onChangeMonthlyEstimatedPayment?.(newMonthlyEstimate);
    onChangeTerm?.(loanOrLeaseTerm);
    let newMonthlyBudget = 0;
    if (Number(loanOrLeaseTerm) > 0) {
      newMonthlyBudget = Math.max(0, salePrice / Number(loanOrLeaseTerm));
    } else {
      newMonthlyBudget = 0;
    }
    setMonthlyBudget(newMonthlyBudget);
  }, [
    salePrice,
    aprOrInterestRate,
    downPayment,
    loanOrLeaseTerm,
    residualValue,
    fuelSavings,
    calculateTotalAfterDeductions,
    calculateMonthlyPayment,
    onChangeMonthlyEstimatedPayment,
    onChangeTerm,
  ]);
  const handleChangeMonthlyEstimatedPayment = useCallback(
    (inputMonthlyEstimatedPayment: string) => {
      const newMEP = Number(inputMonthlyEstimatedPayment);

      setMonthlyEstimatedPayment(newMEP);
      let newSP;
      if (isFinance) {
        newSP = calculateMSRPFinance(
          newMEP,
          aprOrInterestRate,
          Number(loanOrLeaseTerm),
          downPayment,
          tradeInValue,
          incentivesTotal,
          fuelSavings
        );
      } else {
        newSP = calculateMSRPLease(
          newMEP,
          aprOrInterestRate,
          Number(loanOrLeaseTerm),
          residualValue,
          downPayment,
          incentivesTotal,
          fuelSavings
        );
      }

      setSalePrice?.(Math.round(newSP));
      const newTotalAfterDeductions = calculateTotalAfterDeductions();
      setTotalAfterDeductions(newTotalAfterDeductions);
      if (downPaymentType === '%') {
        setDownPaymentPercent(Math.round((downPayment / newSP) * 100));
      }
    },
    [
      aprOrInterestRate,
      loanOrLeaseTerm,
      downPayment,
      tradeInValue,
      setSalePrice,
      calculateTotalAfterDeductions,
      setTotalAfterDeductions,
      downPaymentType,
      fuelSavings,
      incentivesTotal,
      isFinance,
      residualValue,
    ]
  );

  const handleChangeAprOrInterestRate = useCallback(
    (value: string) => {
      const newRate = Number(value);
      if (newRate <= 100) {
        setAprOrInterestRate(newRate);
      }
    },
    [setAprOrInterestRate]
  );

  const handleChangeDownPayment = useCallback(
    (value: string) => {
      let newValue = Number(value);
      if (downPaymentType === '$') {
        setDownPayment(Number(newValue.toFixed(0)));
      } else if (newValue <= 100) {
        setDownPaymentPercent(newValue);
        newValue = Math.floor((newValue / 100) * salePrice);
        setDownPayment(newValue);
      }
    },
    [downPaymentType, salePrice, setDownPayment, setDownPaymentPercent]
  );

  const handleChangeDownPaymentType = useCallback(
    (value: '%' | '$') => {
      setDownPayment(
        Number(((downPaymentPercent * salePrice) / 100).toFixed(0))
      );
      setDownPaymentPercent(
        Math.min(100, Number(((downPayment / salePrice) * 100).toFixed(0)))
      );
      setDownPaymentType(value);
    },
    [
      downPayment,
      downPaymentPercent,
      salePrice,
      setDownPayment,
      setDownPaymentPercent,
      setDownPaymentType,
    ]
  );

  const handleChangeSalePrice = useCallback(
    (value: string) => {
      const newSalePrice = Number(value);
      setSalePrice?.(newSalePrice);
      setTotalAfterDeductions(calculateTotalAfterDeductions());
    },
    [setSalePrice, calculateTotalAfterDeductions]
  );

  const handleChangeTerm = useCallback(
    (term: string) => {
      setLoanOrLeaseTerm(term);
    },
    [setLoanOrLeaseTerm]
  );

  const handleChangeTradeInValue = useCallback(
    (value: string) => {
      setTradeInValue(Number(value));
    },
    [setTradeInValue]
  );

  const handleChangeResidualValue = useCallback(
    (value: string) => {
      let newValue;
      if (residualValueType === '$') {
        newValue = Number(value);
        setResidualValue(newValue);
        setResidualPercent(Number(((newValue / salePrice) * 100).toFixed(0)));
      } else {
        newValue = Number(((Number(value) * salePrice) / 100).toFixed(0));
        setResidualPercent(Number(value));
        setResidualValue(newValue);
      }
    },
    [residualValueType, setResidualValue, setResidualPercent, salePrice]
  );

  const handleChangeResidualValueType = useCallback(
    (value: '%' | '$') => {
      setResidualValue(
        Number(((residualPercent * salePrice) / 100).toFixed(0))
      );
      setResidualValueType(value);
    },
    [residualPercent, salePrice, setResidualValue, setResidualValueType]
  );

  const handleChangeMileageFactor = useCallback(
    (newMilageFactorIndex: number) => {
      setMilageFactorIndex(Number(newMilageFactorIndex));
      const newMileageFactor = mileageOptions[newMilageFactorIndex].value;
      const newResidualPercent =
        Number(residualPercent) + Number(newMileageFactor);
      const newResidualValue = Number(
        ((newResidualPercent * salePrice) / 100).toFixed(0)
      );
      setResidualPercent(newResidualPercent);
      setResidualValue(newResidualValue);
    },
    [
      setMilageFactorIndex,
      mileageOptions,
      residualPercent,
      salePrice,
      setResidualPercent,
      setResidualValue,
    ]
  );

  const handleChangeMode = useCallback(
    (val: 'Finance' | 'Lease') => {
      if (setMode) {
        setMode(val);
      }
    },
    [setMode]
  );

  const downPaymentValid = useMemo(
    () =>
      (downPaymentType === '$' && downPayment >= salePrice) ||
      (downPaymentType === '%' &&
        (downPaymentPercent >= 100 || downPaymentPercent < 0)),
    [downPayment, downPaymentPercent, downPaymentType, salePrice]
  );

  return {
    downPaymentType,
    downPayment,
    downPaymentPercent,
    downPaymentValid,
    tradeInValue,
    loanOrLeaseTerm,
    aprOrInterestRate,
    monthlyEstimatedPayment,
    residualValueType,
    residualValue,
    residualPercent,
    monthlyBudget,
    totalAfterDeductions,
    milageFactorIndex,
    handleChangeMonthlyEstimatedPayment,
    handleChangeDownPayment,
    handleChangeDownPaymentType,
    handleChangeSalePrice,
    handleChangeTerm,
    handleChangeTradeInValue,
    handleChangeAprOrInterestRate,
    handleChangeMode,
    handleChangeResidualValue,
    handleChangeResidualValueType,
    handleChangeMileageFactor,
  };
};
