import {
  IncentiveResponse,
  RawVehicleListingType,
  VehicleListingType,
} from '@/types';
import parseDealer from './parseDealer';
import { parseRange } from './parseRange';
import { parseRawListingPrice, parseRawMileage } from './parseVehicle';
import { filterUntrustedList } from './utils';

export const parseListingVehicle = (
  rawVehicle: RawVehicleListingType
): VehicleListingType => {
  const listing = Array.isArray(rawVehicle.listing)
    ? rawVehicle.listing[0]
    : rawVehicle?.listing;

  const images: string[] = [];
  if (rawVehicle.s3_photo) {
    images.push(rawVehicle.s3_photo);
  } else if (rawVehicle.s3_photos?.[0]) {
    images.push(rawVehicle.s3_photos[0]);
  } else if (listing?.Photo) {
    images.push(listing.Photo);
  } else if (listing?.Photos?.[0]) {
    images.push(listing.Photos[0]);
  }

  return {
    vin: rawVehicle.Vin ?? '',
    year: rawVehicle.Year ?? -1,
    make: rawVehicle.Make ?? '',
    model: rawVehicle.Model ?? '',
    trim: rawVehicle.Trim ?? '',
    body: rawVehicle.Body ?? '',
    fuelType: rawVehicle.FuelType || '',
    listingId: listing?.ListingID ?? '',
    stockNumber: listing?.StockNumber || '',
    dealer: parseDealer(rawVehicle),
    price:
      listing?.Price !== undefined
        ? parseRawListingPrice(listing.Price)
        : { displayValue: 'Coming soon', value: null },
    mileage: listing ? parseRawMileage(listing) : { value: 0, unit: 'mi' },
    images,
    rangeInfo: parseRange(rawVehicle),
    styleId: rawVehicle?.StyleId,

    batteryInfo: {
      batterySize: rawVehicle?.Battery?.size || 'Coming soon',
      batteryTech: rawVehicle?.Battery?.tech || 'Coming soon',
      batteryWarranty: rawVehicle.Battery?.warranty || null,
    },

    drivetrain: rawVehicle.Drivetrain || '',
    badges: filterUntrustedList(rawVehicle.Badges),
    calculatedIncentives: (rawVehicle?.Incentives || null) as {
      Federal: number;
    } | null,
    shortListingId: (rawVehicle?.ShortListingID as string) ?? null,
    incentives: (rawVehicle?.incentives as IncentiveResponse) || null,
  };
};
