import React, { FC } from 'react';
import { DynamicImageWrapper } from '@/components/molecules/DynamicImageWrapper/DynamicImageWrapper';
import { HeartButton } from '@/components/molecules/HeartButton';

interface VLPCardImageContainerProps {
  image: string;
  make: string;
  model: string;
  year: string;
  isMobile: boolean;
  isGridView: boolean;
  handleSaveVehicle: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  saved: boolean;
  savingVehicle: boolean;
}

export const VLPCardImageContainer: FC<VLPCardImageContainerProps> = ({
  image,
  make,
  model,
  year,
  isMobile,
  isGridView,
  handleSaveVehicle,
  saved,
  savingVehicle,
}) => {
  return (
    <div className="flex h-full w-full">
      <div className="absolute z-10 flex p-m">
        <HeartButton
          authDialogImage={image}
          size={isMobile ? 'medium' : 'small'}
          saved={saved}
          onClick={handleSaveVehicle}
          disabled={savingVehicle}
        />
      </div>
      <div className="flex h-full w-full items-center">
        <DynamicImageWrapper
          ariaLabel={`link to build ${year} ${make} ${model}`}
          make={make}
          className={`${
            !isMobile && isGridView ? 'rounded-l-lg' : 'rounded-t-lg'
          }`}
          src={image}
          alt={`build ${year} ${make} ${model}`}
          priority={true}
          isColumnLayout={!isGridView}
          ratio={0.7}
        />
      </div>
    </div>
  );
};
